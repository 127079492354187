import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { css, keyframes } from '@emotion/react';

import { ImLocation } from '@react-icons/all-files/im/ImLocation';
import { ImPhone } from '@react-icons/all-files/im/ImPhone';
import { ImPrinter } from '@react-icons/all-files/im/ImPrinter';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';
import SEO from '../components/seo';
import TrailTitle from '../components/TrailTitle';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

const ContactPage = ({ data, location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!open);

    return () => {
      setOpen(!open);
    };
  }, []);

  return (
    <Layout location={location}>
      <SEO title='Kontak Kami' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <Row
        css={{
          minHeight         : '75vh',
          backgroundImage   : `url(${data.bg.childImageSharp.fixed.src})`,
          backgroundRepeat  : 'no-repeat',
          backgroundPosition: 'top right',
        }}
        className='pt-5 align-content-start'>
        <Col
          xs        = {'12'}
          sm        = {'5'}
          className = 'd-flex flex-column align-items-center align-content-center'>
          <TrailTitle open={open} className='pb-2 mb-5'>
            Kontak Kami
          </TrailTitle>
        </Col>
        <Col xs={'12'} sm={{ span: '8', offset: 2 }} className='mb-1'>
          <h3>Rumah Sakit Gigi dan Mulut</h3>
          <h3>IIK Bhakti Wiyata</h3>
          <Row className='mb-3 mt-4'>
            <Col>
              <ImLocation className='mr-2' />
              Jl. KH. Wahid Hasyim 65, Kediri
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <ImPhone className='mr-2' />
              <a href='tel:+62354774040'>(0354) 774 040</a>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <ImPrinter className='mr-2' />
              <a href='tel:+62354771539'>(0354) 771 539</a>
            </Col>
          </Row>
          <h6 className='mb-3'>Jadwal Praktek</h6>
          <Row>
            <Col xs={'2'} sm={'1'}>
              Senin
            </Col>
            <Col>: 08.00 - 13.00</Col>
          </Row>
          <Row>
            <Col xs={'2'} sm={'1'}>
              Selasa
            </Col>
            <Col>: 08.00 - 13.00</Col>
          </Row>
          <Row>
            <Col xs={'2'} sm={'1'}>
              Rabu
            </Col>
            <Col>: 08.00 - 13.00</Col>
          </Row>
          <Row>
            <Col xs={'2'} sm={'1'}>
              Kamis
            </Col>
            <Col>: 08.00 - 13.00</Col>
          </Row>
          <Row>
            <Col xs={'2'} sm={'1'}>
              Jumat
            </Col>
            <Col>: 08.00 - 13.00</Col>
          </Row>
          <Row>
            <Col xs={'2'} sm={'1'}>
              Sabtu
            </Col>
            <Col>: 08.00 - 12.00</Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export const query = graphql`
  {
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default ContactPage;
